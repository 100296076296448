import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../../SupportingFiles/colors";
import CustomButton from "../../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../../InputFields/RKTextField/RKTextField";
import "../NavBar.scss";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";

function LoginPopup({
  open,
  setOpen,
  showEnterMobileSection,
  setShowEnterMobileSection,
  loginFields,
  setLoginFields,
  fieldsUpdateState,
  handleShowMessage,
  showMessage,
  resendOtp,
}: {
  open: boolean;
  setOpen: any;
  showEnterMobileSection: boolean;
  setShowEnterMobileSection: any;
  loginFields: any;
  setLoginFields: any;
  fieldsUpdateState: Function;
  handleShowMessage: Function;
  showMessage: Function;
  resendOtp: Function;
}) {
  const [loginStep, setLoginStep] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<{
    mobile: { value: string; warning: boolean };
    otp: { value: string; warning: boolean };
  }>({
    mobile: { value: "", warning: false },
    otp: { value: "", warning: false },
  });

  const updateMasterState = (attrName: string, value: any) => {
    setFormData((prev) => ({ ...prev, [attrName]: { value: value } }));
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);

  return (
    <>
      {open ? (
        <Box
          className="fullPagePopup"
          sx={{
            position: "fixed",
            background: COLORS.white,
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            paddingTop: "96px ",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          <Box className="">
            <Box
              sx={{
                height: "72px",
                position: "fixed",
                top: "0px",
                width: "100%",
                zIndex: "99",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                container
                columnSpacing={3}
                alignItems="center"
                width={"100%"}
              >
                <Grid xs={12} textAlign="right">
                  <Link
                    className="closePopup"
                    onClick={() => {
                      setOpen(false);
                      setShowEnterMobileSection(true);
                      setLoginFields({
                        enterMobile: { value: "", warning: false },
                        enterOtp: { value: "", warning: false },
                      });
                    }}
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="content-sec">
              <Grid container columnSpacing={3} className="row content-sec">
                <Grid xs={12} textAlign={"center"} marginBottom={"16px"}>
                  <img src="./images/login.svg" alt="login-img" />
                </Grid>
              </Grid>

              {showEnterMobileSection ? (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  justifyContent={"center"}
                >
                  <Grid xs={12} textAlign={"center"}>
                    <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                      Welcome
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                        marginBottom: "24px",
                        fontSize: "16px",
                        lineHeight: "18px",
                      }}
                    >
                      Login to your Account
                    </p>
                  </Grid>

                  <Grid xs={6}>
                    <Box maxWidth={"330px"} margin={"auto"}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Enter Mobile Number"}
                        value={loginFields.enterMobile.value}
                        attrName={[
                          "enterMobile",
                          "value",
                          loginFields,
                          setLoginFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={loginFields.enterMobile.warning}
                        error_message={
                          isEmpty(loginFields.enterMobile.value)
                            ? "Enter Mobile Number"
                            : "Enter Valid Mobile Number"
                        }
                        max_length={10}
                        validation_type="NUMBER"
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                    <CustomButton
                      className="primaryBtn large"
                      text="Send OTP"
                      onClick={() => {
                        handleShowMessage();
                      }}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  className="row"
                  columnSpacing={3}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid xs={12} textAlign={"center"}>
                    <h3 style={{ fontSize: "28px", marginBottom: "8px" }}>
                      One Time Password
                    </h3>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 400,
                        marginBottom: "24px",
                        fontSize: "16px",
                        lineHeight: "18px",
                      }}
                    >
                      sent successfully on{"  "}
                      <span style={{ fontWeight: 600, fontSize: "16px" }}>
                        {"  "}+91-{loginFields.enterMobile.value}
                      </span>
                      <Link
                        sx={{
                          fontSize: "13px",
                          padding: "2px 14px",
                          borderRadius: "8px",
                          color: COLORS.secondary,
                          border: `1px solid ${COLORS.secondary}`,
                          fontWeight: 500,
                          marginLeft: "12px",
                          display: "inline-block",
                          lineHeight: "18px",
                        }}
                        onClick={() => setShowEnterMobileSection(true)}
                      >
                        Edit
                      </Link>
                    </p>
                  </Grid>
                  <Grid xs={8}>
                    <Box maxWidth={"330px"} margin={"auto"}>
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Enter OTP"}
                        value={loginFields.enterOtp.value}
                        attrName={[
                          "enterOtp",
                          "value",
                          loginFields,
                          setLoginFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={loginFields.enterOtp.warning}
                        error_message={
                          isEmpty(loginFields.enterOtp.value)
                            ? "Enter OTP"
                            : "Enter Valid OTP"
                        }
                        max_length={4}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} textAlign={"center"} marginBottom={"24px"}>
                    <CustomButton
                      className="primaryBtn large"
                      text="Verify OTP"
                      onClick={() => {
                        showMessage();
                      }}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <p style={{ textAlign: "center", fontSize: "16px" }}>
                      Didn’t receive?{" "}
                      <Link
                        sx={{
                          fontSize: "13px",
                          padding: "2px 14px",
                          borderRadius: "8px",
                          color: COLORS.secondary,
                          border: `1px solid ${COLORS.secondary}`,
                          fontWeight: 500,
                          marginLeft: "12px",
                          display: "inline-block",
                          lineHeight: "18px",
                        }}
                        onClick={() => {
                          setLoginFields({
                            ...loginFields,
                            enterOtp: { value: "", warning: false },
                          });
                          resendOtp();
                        }}
                      >
                        Resend
                      </Link>
                    </p>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default LoginPopup;
