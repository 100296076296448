import { PayloadAction } from "@reduxjs/toolkit";
import { TuserData } from "../../Type/Home/TUserdata";
import { TUserDataSlice } from "../../Type/Home/TUserDataSlice";

function setAccountUserData(
  state: TUserDataSlice,
  action: PayloadAction<TuserData>
) {
  const updatedState: TUserDataSlice = {
    ...state,
    USER_DATA: {
      ...state.USER_DATA,
      ...action.payload,
    },
  };
  return updatedState;
}

function setAccountUserDataMobile(
  state: TUserDataSlice,
  action: PayloadAction<string>
) {
  const updatedState: TUserDataSlice = {
    ...state,
    USER_DATA: {
      ...state.USER_DATA,
      mobile: { ...state.USER_DATA.mobile, value: action.payload },
    },
  };
  return updatedState;
}

type ValueTypes = keyof TUserDataSlice["USER_DATA"];

function setAccountUserDataMultipleKey(
  state: TUserDataSlice,
  action: PayloadAction<{ key: ValueTypes; value: any }[]>
) {
  const updatedState: TUserDataSlice = {
    ...state,
    USER_DATA: {
      ...state.USER_DATA,
    },
  };

  if (Array.isArray(action.payload)) {
    for (const { key, value } of action.payload) {
      if (key in updatedState.USER_DATA) {
        updatedState.USER_DATA[key] = { value, warning: false };
      } else {
        console.error(`Key '${key}' does not exist in USER_DATA.`);
      }
    }
  } else {
    console.error("Invalid payload format. Expected an array.");
  }

  return updatedState;
}

export const USER_REDUCERS = {
  setAccountUserData,
  setAccountUserDataMobile,
  setAccountUserDataMultipleKey,
};

export type TwoTermReducers = typeof USER_REDUCERS;
